<template>
  <v-app id="login-home">
    <public-view />
  </v-app>
</template>

<script>
export default {
  name: "PublicLayout",
  components: {
    PublicView: () => import("./View"),
  },
  mounted() {
    // this.$vuetify.theme.dark = false;
  },
};
</script>
